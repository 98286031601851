<template>
  <v-dialog
    v-model="dialogMudarStatusKanban"
    @click:outside="$emit('update:dialogMudarStatusKanban', false)"
    @keydown.esc="$emit('update:dialogMudarStatusKanban', false)"
    max-width="416px"
  >
    <v-card flat>
      <v-card-title style="background: #232d58">
        <span class="text--white" style="color: white">
          {{ $tc("global.alterar").toUpperCase() }}
          {{ $tc("global.status").toUpperCase() }}
        </span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          color="white"
          small
          @click="$emit('update:dialogMudarStatusKanban', false)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form @submit.prevent ref="form" v-model="validForm" lazy-validation>
          <v-row>
            <v-col cols="12" md="12">
              <v-select
                v-model="selectedStatusChange"
                :items="statusFilter"
                :rules="formRules"
                :label="$tc('global.status')"
                outlined
                dense
                hide-details
                class="mt-4"
                return-object
              />
            </v-col>
            <v-col md="12" cols="12" v-if="selectedStatusChange != null && selectedStatusChange.value == 5 ? true : false">
              <v-file-input
                required
                suffix="Max 100MB"
                :messages="
                  $tc('global.formatosPermitidos') +
                  ' : .jpg, .png, .txt, .xls, .xlsx, .pdf, .doc, .docx, .odt.'
                "
                v-model="file"
                :label="$tc('global.termoEntregaAssinado')"
                :placeholder="$tc('global.anexos')"
                accept=".jpg,.png,.txt,.xls,.xlsx,.pdf,.doc,.docx,.odt"
                class="mb-2 mx-2"
                prepend-icon="mdi-paperclip"
              >
                <template v-slot:selection="{ text }">
                  <v-chip
                    small
                    label
                    close
                    close-icon="mdi-delete"
                    color="primary"
                    @click:close="remove(text)"
                  >
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>
            </v-col>

            <v-col cols="12" md="12">
              <v-textarea
                v-model="observacao"
                :rules="formRules"
                :label="$tc('global.observacao')"
                outlined
                dense
                hide-details
                rows="1"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="px-6 pb-4 pt-0">
        <v-spacer></v-spacer>
        <v-btn
          depressed
          color="green"
          class="mr-3"
          outlined
          @click="$emit('update:dialogMudarStatusKanban', false)"
        >
          Cancelar
        </v-btn>

        <v-btn
          color="green"
          style="color: white"
          :disabled="!validForm"
          :loading="loading"
          @click="postMudancaStatus"
        >
          salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { osKanbanStatus } from "@/api/ordem-servico/ordem_servicos.js";
import { inputRequired } from "@/helpers/utils.js";
export default {
  name: "DialogMudarStatusKanban",

  props: {
    dialogMudarStatusKanban: {
      type: Boolean,
    },
    ordemServico: {
      type: [String, Number],
    },
    statusId: {
      type: [String, Number],
    },
  },

  data() {
    return {
      loading: true,
      selectedStatusChange: null,
      observacao: null,
      validForm: true,
      formRules: [inputRequired],
      status: [
        {
          text: this.$tc("global.pendentes"),
          value: 1,
        },
        {
          text: this.$tc("global.emPreparação"),
          value: 2,
        },
        {
          text:
            this.$tc("global.aguardando") + " " + this.$tc("global.instalacao"),
          value: 3,
        },
        {
          text: "Em " + this.$tc("global.instalacao"),
          value: 4,
        },
        {
          text: this.$tc("global.finalizado"),
          value: 5,
        },
      ],
      file: null,
    };
  },

  methods: {
    postMudancaStatus() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        const statusKanban = new FormData();
        statusKanban.append("observacao", this.observacao);
        statusKanban.append("status_text", this.selectedStatusChange.text);
        statusKanban.append("status_id", this.selectedStatusChange.value);
        if(this.file != null) {
          statusKanban.append("file", this.file);
        }
        statusKanban.append("origem", "ORDEM_SERVICO");
        statusKanban.append("origem_id", this.ordemServico);

        osKanbanStatus(this.ordemServico, statusKanban).then((response) => {
          this.loading = false;
          this.$emit("update:dialogMudarStatusKanban", false);
          this.$emit("fetch-ordens-servicos");
        });
      }
    },
    remove(item) {
      this.file = [];
    },
  },

  computed: {
    statusFilter() {
      return this.status.filter((item) => item.value !== this.statusId);
    },
  },

  watch: {},

  mounted() {
    this.loading = false;
  },
};
</script>

<style></style>
